import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {Navigate, useLocation, Link, useNavigate} from "react-router-dom";
import UprightNav from "../components/navbar";
import {Container, Row, Col, Table, Spinner, Alert} from "react-bootstrap";
import {useShoppingCart} from "use-shopping-cart";
import {Helmet} from "react-helmet";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";
import closedModal from "../components/closedModal";
import ClosedModal from "../components/closedModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CheckoutForm = (operatingStatus) => {
    const {
        cartDetails,
    } = useShoppingCart();
    const auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser || '');
    const [clientSecret, setClientSecret] = useState('');
    const [discountError, setDiscountError] = useState('');
    const location = useLocation();
    const deliveryInformation = useState(location.state.deliveryInformation);
    const navigate = useNavigate();
    const currentPath = window.location.pathname;
    const inboundError = location.state?.error
    const tip = sessionStorage?.getItem('tip')
    const couponDetails = JSON.parse(sessionStorage?.getItem('couponDetails') || '{}');
    const couponCode = couponDetails.couponcode || '';
    console.log(couponCode)


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                signInAnonymously(auth).then((credential) => {
                    setUser(credential.user);
                });
            }
            if (!deliveryInformation) {
                navigate(`/checkout/`, {state: {error: 'Please re-enter your delivery information'}});
                return;
            }
            if(user && cartDetails) {
            let ignore = false;
            const reqBody = {
                cartDetails,
                'deliveryInformation': deliveryInformation,
                'user':
                    {
                        'userId': user.uid,
                        'email': user.email || null
                    },
                'tip': tip,
                'couponCode': couponCode
            };
            fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/create-checkout-session`, {
                method: "POST",
                body: JSON.stringify(reqBody),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (!ignore) {
                        setClientSecret(data.clientSecret);
                        data.discountError && setDiscountError(data.discountError);
                    }
                })
            return () => {
                ignore = true
            }
         }
    });
        return () => unsubscribe();
    }, [auth, user, tip, cartDetails]);






    // function handleComplete() {
    //     console.log('Payment completed');
    //     navigate("/confirmation", { state: { orderDetails: cartDetails } });
    // }

    return (
        <Container>
            <Helmet>
                <title>Checkout : Upright Bites</title>
            </Helmet>
            <ClosedModal operatingStatus={operatingStatus} />
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col xl={4}>
                    <h1>Checkout</h1>
                </Col>
                <Col xl={4}>
                    {Object.keys(cartDetails).length > 0 && (
                        <>
                        <p className={'text-secondary mb-1'}>Your order from:</p>
                    <h6 className={'mb-1 ps-2'}>{Object.values(cartDetails)[0].product_metadata.restaurantName}</h6>
                    <p className="text-secondary mb-4 ps-2">{Object.values(cartDetails)[0].product_metadata.restaurantAddress1}{Object.values(cartDetails)[0].product_metadata.restaurantAddress2 ? `, ${Object.values(cartDetails)[0].product_metadata.restaurantAddress2}` : ''}, {Object.values(cartDetails)[0].product_metadata.restaurantCity} {Object.values(cartDetails)[0].product_metadata.restaurantState} {Object.values(cartDetails)[0].product_metadata.restaurantZipcode}</p>
                        </>
                    )}
                    </Col>
            </Row>
            <Row>
                <Col>
                    <Table className={"table"} style={{borderRadius: '15px', overflow: 'hidden'}}>
                        <tr style={{height: '3rem'}}>
                            <td className={"text-center text-black bg-light"} style={{width: '50%'}}><Link
                                to={'/checkout/address/'}
                                className={"text-black bg-light link-underline link-underline-opacity-0"}
                                data-umami-event={"Verify address stepper"}
                                >
                                Verify Address
                                </Link></td>
                            <td className={"text-center text-white bg-secondary fw-bold"}
                                style={{width: '50%'}}>Payment
                            </td>
                        </tr>
                    </Table>
                </Col>
            </Row>
            <div id="checkout">
                {!clientSecret && (
                    <div className={'text-center p-4'}>
                        <p>Loading...</p>
                        <Spinner animation="border" role="status" size='lg' >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      </div>
                )}
                {clientSecret && (
                    <>
                    <Alert variant="warning" show={discountError} data-umami-event={"Payment page coupon error "}>Your order is not elgible for coupon <i>{couponCode}</i>.
                    {
                        discountError === 'maxOrdersError' && couponDetails.previousordersmax === 0 ? ' This coupon is for new customers only. '
                        : discountError === 'minNotMetError' ? `This coupon requires a minimum order of $${couponDetails.minorderamount}.`
                        : ' Please check the coupon terms. '
                    }  
                     Return to <Link className='text-secondary' to={'/cart'} data-umami-event={"Payment page coupon error: return to cart link"}>your cart</Link> to modify your order or to use another coupon.
                        </Alert>
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{
                            clientSecret
                        }}
                    >
                        <EmbeddedCheckout/>
                    </EmbeddedCheckoutProvider>
                    </>
                )}
            </div>
        </Container>
    )
}
export default CheckoutForm;