import React, { useState, useEffect } from 'react';
import {Accordion, Container, Row, Col, Card, Button, Modal, Form, InputGroup, ListGroup, Badge} from 'react-bootstrap';
import { useShoppingCart } from 'use-shopping-cart';
import {useParams, useNavigate, Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {CartFill, Search, Copy, TagFill} from 'react-bootstrap-icons';
import TiktokPixel from "tiktok-pixel";

function MenuPage({operatingStatus}) {
    console.log('MenuPage component mounted');
    const { addItem, cartDetails, clearCart, cartCount } = useShoppingCart();
    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState(0);
    const [selectedspecialInstructions, setspecialInstructions] = useState('')
    const navigate = useNavigate()
    const [restaurantData, setRestaurantData] = useState([]);
    const [inventoryModifierData, setInventoryModifierData] = useState({});
    const {restaurantId} = useParams();
    const [itemSearchTerm, setItemSearchTerm] = useState('');
    const [multipleRestaurantError, setMultipleRestaurantError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [modifierError, setModifierError] = useState({});
    const [inventoryModifierOptions, setInventoryModifierOptions] = useState({});
    const [modifierTotalPrice, setModifierTotalPrice] = useState(0);

    TiktokPixel.pageView(); // For tracking page view

    // Function to handle scroll event
function handleScroll() {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPercentage = (scrollTop + windowHeight) / documentHeight * 100;

    if (scrollPercentage >= 25 && !window.hasScrolled25) {
        recordUmamiScrollEvent(25);
        window.hasScrolled25 = true;
    } else if (scrollPercentage >= 50 && !window.hasScrolled50) {
        recordUmamiScrollEvent(50);
        window.hasScrolled50 = true;
    } else if (scrollPercentage >= 75 && !window.hasScrolled75) {
        recordUmamiScrollEvent(75);
        window.hasScrolled75 = true;
    } else if (scrollPercentage >= 100 && !window.hasScrolled100) {
        recordUmamiScrollEvent(100);
        window.hasScrolled100 = true;
    }
}

function recordUmamiScrollEvent(percentage) {
    window.umami.track(`Scrolled ${percentage}%`);
}

// Add scroll event listener
window.addEventListener('scroll', handleScroll);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/restaurant/menu/${restaurantId}`);
                const data = await response.json();
                setRestaurantData(data);
                console.log('Menu data fetched successfully!');
            } catch (error) {
                console.error('Error fetching menu:', error);
            }
        };

        fetchData();

        return () => {
            console.log('Unmounting MenuPage component');
        };
    }, [restaurantId]);

    const checkForModifierErrors = (inventoryModifierId, minModifiers, maxModifiers) => {
        const value = inventoryModifierOptions[inventoryModifierId] ? inventoryModifierOptions[inventoryModifierId].length : 0;
        if(minModifiers > 0 && value < minModifiers){
            return true;
        }
        if(maxModifiers > 0 && value > maxModifiers){
            return true;

        }
        else{
            return null;
        }
    }

    const handleViewCart = () => {
        navigate('/cart');
        };
    async function handleAddToCart(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        //run validateModifier for each modifier
        for (const modifier of inventoryModifierData.inventoryModifiers) {
            const error = await checkForModifierErrors(modifier.inventoryModifierId, modifier.minModifiers, modifier.maxModifiers);
            if(error){
                setValidationError(true)
                setValidated(false)
                return
            }
        }
        if (selectedItem) {
            console.log(Object.values(cartDetails)[0])
            if (Object.values(cartDetails)[0] && Object.values(cartDetails)[0].product_metadata  && Object.values(cartDetails)[0].product_metadata.restaurantId !== restaurantData.restaurantId) {
                setMultipleRestaurantError(true);
                setValidated(false);
                window.umami.event('Multiple restaurant error');
                console.log('multiple restaurant error');
                return;
            }
            else{
                setMultipleRestaurantError(false);
                // setValidated(true);
                addItem({
                    name: selectedItem.name,
                    product_metadata: {
                        specialInstructions: selectedspecialInstructions,
                        restaurantId: restaurantData.restaurantId,
                        restaurantName: restaurantData.name,
                        restaurantAddress1: restaurantData.address1,
                        restaurantAddress2: restaurantData.address2,
                        restaurantCity: restaurantData.city,
                        restaurantState: restaurantData.state,
                        restaurantZipcode: restaurantData.zipcode,
                        restaurantCuisine: restaurantData.cuisine,
                        restaurantLat: restaurantData.lat,
                        restaurantLong: restaurantData.long,
                        modifiers: {...inventoryModifierOptions},
                    },
                    id: selectedItem.id,
                    description: selectedItem.description,
                    price: (selectedItem.price + modifierTotalPrice),
                    currency: selectedItem.currency
                    }, { count: selectedQuantity});
                setSelectedQuantity(0);
                setInventoryModifierOptions({});
                setValidationError(false);
                setShowModal(false);
            };
        };
    };

    const handleReturnToOtherMenu = () => {
        navigate(`/menu/${Object.values(cartDetails)[0].product_metadata.restaurantId}`)
        return;
    }

    const handleShowModal = (item) => {
        const fetchInventoryModifierData = async (item) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/restaurant/menu/inventory/${item.id}`);
                const data = await response.json();
                setInventoryModifierData(data);
            } catch (error) {
                console.error('Error fetching inventory modifier data:', error);
            }
        };
        fetchInventoryModifierData(item)
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setShowModal(false);
    };


    const filteredMenu = restaurantData.menu
    ? restaurantData.menu.map(category => ({
        ...category,
        items: category.items.filter(item => {
            const searchTerm = itemSearchTerm ? itemSearchTerm.toLowerCase() : '';
            const itemName = item.name ? item.name.toString().toLowerCase() : '';
            const itemDescription = item.description ? item.description.toString().toLowerCase() : '';
            return itemName.includes(searchTerm) || itemDescription.includes(searchTerm);
        }),
    }))
    : [];

    if (!restaurantData.menu || !restaurantData){
        return(
        <Container>
            <Row className="mt-5">
                <Col>
                    <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
                </Col>
            </Row>
        </Container>
        )
    }

    function fullOrderExp() {

        return (
            <>
            <Row>
                <Col>
                    <Form onSubmit={e => e.preventDefault()}>
                        <InputGroup controlId="searchBar">
                            <InputGroup.Text><Search /></InputGroup.Text>
                            <Form.Control type="text" placeholder="Search menu items..."  value={itemSearchTerm} onChange={e => setItemSearchTerm(e.target.value.toLowerCase())} data-umami-event={"Menu search field"} />
                        </InputGroup >
                    </Form>
                </Col>
            </Row>
            <Row className="mt-1 d-none d-md-block">
            <Col>
                <ul className="nav nav">
                <li className="nav-item">
                    <div className="nav-link text-secondary fw-semibold">Categories:</div>
                </li>
                {restaurantData.menu.map((category, index) => (
                    <li key={index} className="nav-item">
                    <a className="nav-link text-secondary" href={`#${category.category.toLowerCase()}`} data-umami-event={"Category jump link"}>{category.category}</a>
                    </li>
                ))}
                </ul>
            </Col>
            </Row>

            <Row className="mt-1 d-md-none">
            <Col>
                <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Categories</Accordion.Header>
                    <Accordion.Body>
                    <ul className="nav nav">
                        {restaurantData.menu.map((category, index) => (
                        <li key={index} className="nav-item">
                            <a className="nav-link text-secondary" href={`#${category.category.toLowerCase()}`} data-umami-event={"Category jump link"}>{category.category}</a>
                        </li>
                        ))}
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </Col>
            </Row>
        {filteredMenu.map((category, index) => (
            <React.Fragment key={index}>
                <Row className="mt-1 mb-1">
                    <Col>
                        <h4 id={category.category.toLowerCase()}>{category.category}</h4>
                    </Col>
                </Row>
                <Row>
                    {category.items.map((item, idx) => (
                        <Col xs={12} sm={6} md={4} lg={3} key={idx} className="mb-3">
                            <Card style={{ width: '100%', height: '100%', cursor: 'pointer' }} key={idx}>
                                <Card.Body onClick={() => handleShowModal(item)} data-umami-event={"Select item card"} >
                                    <Card.Title>{item.name}</Card.Title>
                                    <Card.Text className="text-secondary">{item.description}</Card.Text>
                                    <Card.Text>${(item.price * 0.01).toFixed(2)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </React.Fragment>
        ))}

        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedItem && selectedItem.name} - ${selectedItem && ((selectedItem.price + modifierTotalPrice) * 0.01).toFixed(2)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card.Text>{selectedItem && selectedItem.description}</Card.Text>
                <Form noValidate validated={validated} onSubmit={handleAddToCart}>
    {inventoryModifierData.inventoryModifiers && inventoryModifierData.inventoryModifiers.map((modifier) => (
                    <Form.Group  
                    key={modifier.id}
                    hasValidation
                    >
                        <Form.Label className='mb-2' >
                            <h6>{modifier.name} {modifier.minModifiers > 0 && <span className='text-danger text-bold'> *</span>}</h6>
                            {modifierError[modifier.inventoryModifierId] 
                                ? <Badge bg="danger">{modifier.minModifiers === modifier.maxModifiers ? `Choose ${modifier.maxModifiers}`  : modifier.minModifiers === 0 && modifier.maxModifiers > 0 ? `Choose up to ${modifier.maxModifiers}` : modifier.minModifiers != 0 && modifier.minModifiers != modifier.maxModifiers && modifier.maxModifiers > 1 ? `Choose ${modifier.minModifiers} to ${modifier.maxModifiers}` : ''}</Badge> 
                                : <span className={'text-secondary'}>{modifier.minModifiers === modifier.maxModifiers ? `Choose ${modifier.maxModifiers}`  : modifier.minModifiers === 0 && modifier.maxModifiers > 0 ? `Choose up to ${modifier.maxModifiers}` : modifier.minModifiers != 0 && modifier.minModifiers != modifier.maxModifiers && modifier.maxModifiers > 1 ? `Choose ${modifier.minModifiers} to ${modifier.maxModifiers}` : ''}</span>}                           </Form.Label>
                        {modifier.options.map((option, idx) => (
                            <React.Fragment key={option.inventoryModifierOptionId}>
                                <Form.Check className='mb-2'
                                    type='checkbox'
                                    name="modifierOptions"
                                    value={option.inventoryModifierOptionId}
                                    label={`${option.name} - $${(option.price * 0.01).toFixed(2)}`}
                                    isInvalid={modifierError[modifier.inventoryModifierId]}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setInventoryModifierOptions({
                                                ...inventoryModifierOptions,
                                                [modifier.inventoryModifierId]: [
                                                    ...(inventoryModifierOptions[modifier.inventoryModifierId] || []),
                                                    {
                                                        id: option.inventoryModifierOptionId,
                                                        name: option.name,
                                                        price: option.price
                                                    }
                                                ]
                                            }                                        
                                        );
                                        setModifierTotalPrice(modifierTotalPrice + option.price);
                                        } else {
                                            // Remove inventoryModifierOptionId from inventoryModifierOptions object
                                            setInventoryModifierOptions({
                                                ...inventoryModifierOptions,
                                                [modifier.inventoryModifierId]: inventoryModifierOptions[modifier.inventoryModifierId].filter(
                                                    (item) => item.id !== option.inventoryModifierOptionId
                                                )
                                            });
                                            setModifierTotalPrice(modifierTotalPrice - option.price);
                                        }
                                        }
                                    }
                                    onBlur={() => {
                                        const error = checkForModifierErrors(modifier.inventoryModifierId, modifier.minModifiers, modifier.maxModifiers);
                                        setModifierError({...modifierError, [modifier.inventoryModifierId]: error});
                                    }}
                                />
                        
                            </React.Fragment>
                        ))}
                    </Form.Group>
                    ))}
                    <Form.Group controlId="specialInstructions">
                        <Form.Label><h6>Special Instructions</h6></Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="" value={selectedspecialInstructions} onChange={e => setspecialInstructions(e.target.value)} />
                    </Form.Group >
                    <Form.Group controlId="quantity" className="mt-2">
                        <Form.Label><h6>Quantity</h6></Form.Label>
                        <Form.Select
                            onChange={e => setSelectedQuantity(parseInt(e.target.value))}
                            data-umami-event={"Quantity selected"} 
                            >
                            <option defaultValue={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </Form.Select>
                    </Form.Group>
                    <Button className="mt-3" type="submit" variant="primary" data-umami-event={"Add to cart button"} 
                >
                    Add to Cart
                </Button>
                    </Form>
            </Modal.Body>

                {multipleRestaurantError && (
                    <div className="alert alert-danger" role="alert"><p>You have items from another restaurants in your cart.</p><p>Please complete your order with <Link to={`/menu/${Object.values(cartDetails)[0].product_metadata.restaurantId}`} onClick={() => { handleCloseModal(); setMultipleRestaurantError(false); }}>{Object.values(cartDetails)[0].product_metadata.restaurantName}</Link> or <Link onClick={() => { clearCart(); setMultipleRestaurantError(false); }}>clear your cart</Link>.</p></div>
                )
                }
                {validationError && (
                    <div className="alert alert-danger" role="alert">Please complete all required fields.</div>
                )
                }
        </Modal>
        {cartCount > 0 && (
                <Button
                    variant="secondary"
                    className="d-md-none fixed-bottom m-3 flex-fill-1"
                    onClick={handleViewCart}
                    data-umami-event={"Floating view cart button"} 
                    >
                    View Cart ({cartCount})
                </Button>
            )}
            </>
        )
    }

    function directDineExp() {

        return (
            <>
                <Container className="bg-light pt-4 pb-4">
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col lg={8} xl={6}>
                            <img className="img-fluid w-100" src={restaurantData.cardimage}/><br/>
                            <p>
                                {restaurantData.name} is available through our Direct Dine program.
                            </p>
                            <p>
                                Place your order directly with {restaurantData.name} and we'll handle the delivery for
                                just $5 (free for your first Upright Bites order!). It's easy, we'll walk you through
                                it!</p>
                            {operatingStatus.status === 'closed' ?
                                <Card>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className={'text-center'}>
                                            {operatingStatus.reason === 'override' ? (operatingStatus.message || "We are currently closed. Please try again later.") : null}
                                            {operatingStatus.reason === 'hours' && `We are currently closed. Our normal operating hours are ${operatingStatus.open.formatted} to ${operatingStatus.close.formatted} Monday - Sunday. Please come back during our operating hours to place an order.`}
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className="d-grid gap-2 mb-3">
                                                <Button className={"align-items-center mt-2"} variant="secondary"
                                                        disabled>Start my order
                                                </Button>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card>
                                :
                                <div className="d-grid gap-2 mb-3">
                                    <Button className={"align-items-center mt-2"} variant="primary"
                                            onClick={() => navigate(`/direct-dine/${restaurantId}`)}>Start my
                                        order</Button>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>

            </>
        )
    }

    console.log('Value of restaurantData.directdine:', restaurantData.directdine);


    return (
        <Container>
            <Helmet>
                <title>Upright Bites: {restaurantData.name}</title>
            </Helmet>
            <Row className="mt-3">
                <Col>
                    <h1>{restaurantData.name}</h1>
                    <p className="text-secondary">
                        {restaurantData.address1}{restaurantData.address2 ? `, ${restaurantData.address2}` : ''}, {restaurantData.city}, {restaurantData.state} {restaurantData.zipcode} |
                        Cuisine: {restaurantData.cuisine}
                    </p>
                </Col>
                {/* <div className={'position-relative mt-1'}>
                <span className="badge bg-success position-absolute bottom-0 end-0" onClick={console.dir(inventoryModifierOptions)}>
                        <TagFill/> 40% off your first order
            </span>
            </div> */}
            </Row>
            {
                restaurantData.directdine === true ?
                    directDineExp()
                    :
                    fullOrderExp()
            }
        </Container>
    )
        ;
}

export default MenuPage;
