import {Container, Navbar, Nav, Button, Badge, Alert, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/styles.css';
import logo from '../assets/brand/Logo-no words.jpg';
import {CartFill, Cart} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { useShoppingCart } from 'use-shopping-cart';


function UprightNav({ operatingStatus }) {
    const { cartCount } = useShoppingCart();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(true);

    console.log('config is', operatingStatus)

    const handleViewCart = () => {
        window.umami.track('Cart nav click');
        navigate('/cart');
    };

    const handleBrandClick = () => {
        window.umami.track('Brand nav click');
        navigate('/');
    }

    const handleHomeLinkClick = () => {
        window.umami.track('Home nav click');
        navigate('/');
    }

    const handleRestarauntLinkClick = () => {
        window.umami.track('Order nav click');
        navigate('/order');
    }

    const handleAboutLinkClick = () => {
        window.umami.track('About nav click');
        navigate('/about');
    }

    const handleAccountLinkClick = () => {
        window.umami.track('Account nav click');
        navigate('/account');
    }

    const handleJoinLinkClick = () => {
        window.umami.track('Add your restaurant nav click');
        navigate('/partners/join')
    }

    const shoppingCartNavIcon = () => {
        return (
            <Nav className={"d-flex ms-auto pe-1"}>
                {cartCount > 0 && (
                    <Button variant="btn btn-link" onClick={handleViewCart} data-umami-event={"Filled cart icon click"}>
                        <CartFill size={25} title={"Shopping bag"} color={"secondary"}/>
                        <Badge bg="primary">{cartCount}</Badge>
                    </Button>
                )}
                {!cartCount && (
                    <Button variant="btn btn-link" onClick={handleViewCart} data-umami-event={"Empty cart icon click"}>
                        <Cart size={25} title={"Shopping cart"} color={"secondary"}/>
                        <span className="visually-hidden">items in shopping cart</span>
                    </Button>
                )}
            </Nav>
        )
    }

    const openClosedAlert = () => {
        if(operatingStatus.status === 'closed') {
                return (
                    <Alert variant="secondary" className={'mb-0 text-center'} onClose={() => setShowAlert(false)} dismissible>
                        {operatingStatus.reason === 'override' ? (operatingStatus.message || "We are currently closed. Please try again later.") : null}
                        {operatingStatus.reason === 'hours' && `We are currently closed. Our normal operating hours are ${operatingStatus.open.formatted} to ${operatingStatus.close.formatted} Monday - Sunday. Please come back during our operating hours to place an order.`}
                    </Alert>
                )
            }}


    return (
        <>
            {openClosedAlert()}
            <Navbar expand="lg" >
                <Container>
                    <Navbar.Brand style={{cursor: 'pointer'}} onClick={handleBrandClick}>
                        <img src={logo} alt="Upright Bites Logo" height={50} className="d-inline-block me-3"/>Upright Bites
                    </Navbar.Brand>
                    <div className="d-lg-none d-block ms-auto">
                        {shoppingCartNavIcon()}
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" data-umami-event={"Navbar toggle click"} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link onClick={handleHomeLinkClick}>Home</Nav.Link>
                            <Nav.Link onClick={handleAboutLinkClick}>About</Nav.Link>
                            <Nav.Link onClick={handleRestarauntLinkClick}>Order</Nav.Link>
                            <NavDropdown className={""} title="Restaurant Partners" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={handleJoinLinkClick}>Add your restaurant</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        {/* <Nav className="ms-auto">
                            <Nav.Link onClick={handleAccountLinkClick}>Account</Nav.Link>
                        </Nav> */}
                    </Navbar.Collapse>
                    <div className="d-lg-block d-none">
                        {shoppingCartNavIcon()}
                    </div>
                </Container>
            </Navbar>
        </>
    )
}

export default UprightNav;