import React, {useEffect, useId, useState} from 'react';
import {useShoppingCart} from 'use-shopping-cart';
import UprightNav from '../components/navbar'
import {useNavigate, Link} from "react-router-dom";
import {
    Container,
    Button,
    Tooltip,
    Overlay,
    Row,
    Col,
    ListGroup,
    Card,
    OverlayTrigger,
    InputGroup, Form
} from "react-bootstrap";
import {CartX, QuestionCircle, TagFill} from "react-bootstrap-icons";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";
// import {useStripe, useElements, PaymentElement, loadStripe} from '@stripe/react-stripe-js';


const ShoppingCartPage = ({ operatingStatus }) => {
    const navigate = useNavigate();
    const {
        cartDetails,
        formattedTotalPrice,
        incrementItem,
        decrementItem,
        removeItem,
        setItemQuantity,
        totalPrice,
        cartCount
    } = useShoppingCart();
    const [IsTipValid, setIsTipValid] = useState(true);
    const [tip, setTip] = useState(sessionStorage?.getItem('tip'));
    let auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser);
    const [couponDetails, setCouponDetails] = useState(() => {
        const savedCouponDetails = sessionStorage.getItem('couponDetails');
        return savedCouponDetails ? JSON.parse(savedCouponDetails) : {};
    });
    const [couponCode, setCouponCode] = useState('');
    const [discountAmount, setDiscountAmount] = useState(0);
    const [minNotMetError, setminNotMetError] = useState(false);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                signInAnonymously(auth).then((credential) => {
                    setUser(credential.user);
                });
            }
            return () => unsubscribe();
        }
    );
    }, [auth, user]);

    useEffect(() => {
        sessionStorage.setItem('couponDetails', JSON.stringify(couponDetails));
    }, [couponDetails]);

    //cart math
    const deliveryFee = 0;
    const orderSubtotal = ((totalPrice + deliveryFee + (tip * 100)) - (discountAmount||0));
    const orderSubtotalFormatted = (orderSubtotal * .01).toFixed(2);
    

    useEffect(() => {
        if(couponDetails && couponDetails !== 'error') {
        if (totalPrice >= (couponDetails.ordermin||0)) {
            setminNotMetError(false);
            let discount = 0;   
                if (couponDetails.type === 'percent') {
                    discount = Math.min((totalPrice * couponDetails.discount), couponDetails.discountmax);
                    setDiscountAmount(discount);
                } else if (couponDetails.type === 'fixed') {
                    discount = couponDetails.discount;
                    setDiscountAmount(discount);

                }
            }
        else {
            setDiscountAmount(0);
            setminNotMetError(true);
        }
    }
    else{
        setDiscountAmount(0);
    }
    }, [totalPrice, couponDetails]);


    const handleCheckoutClick = () => {
        // Use the useNavigate hook to navigate to the desired page
        navigate(`/checkout`);
    };

    const getCouponDetails = (couponCode) => {
        if(couponCode === '') {
            setCouponDetails('');
            return;
        }
        fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/coupons/${couponCode}`)
            .then(response => response.json())
            .then(couponDetails => {
                Object.keys(couponDetails).length > 0 ? setCouponDetails(couponDetails) : setCouponDetails('error');
                return;
                    })
            .catch(error => {
                console.error('Error fetching coupon details:', error);
                setCouponDetails('error');
                return
            });
    }

    const handleIncrement = (item) => {
        incrementItem(item.id);
    };

    const handleDecrement = (item) => {
        decrementItem(item.id);
    };

    const handleRemove = (item) => {
        removeItem(item.id);
    };

    async function updateTip(event) {
        const value = event.target.value;
        const pattern = /^\$?\d{1,6}(\.\d{1,2})?$/; // Modified pattern
        if (pattern.test(value) || value === '') {
            let tipValue = value === '' ? 0 : parseFloat(value.replace('$', ''));
            setTip(tipValue);
            sessionStorage.setItem('tip', tipValue.toString());
            setIsTipValid(true);
        } else {
            setIsTipValid(false);
        }
    }

    return (
        <Container>
            {/* Header */}
            <Row className="d-flex align-items-center justify-content-center p-2">
                <Col xl={4}>
                    <h1>Shopping Cart</h1>
                </Col>
                <Col xl={4}>
                    {cartCount > 0 &&
                        <>
                        <p className={'text-secondary mb-1'}>Your order from:</p>
                        <div style={{cursor: 'pointer'}} onClick={() => navigate(`/menu/${Object.values(cartDetails)[0].product_metadata.restaurantId}`)} data-umami-event={"Restaurant info click"}>
                    <h6 className={'mb-1 ps-2'}>{Object.values(cartDetails)[0].product_metadata.restaurantName}</h6>
                                <p className="text-secondary mb-4 ps-2">{Object.values(cartDetails)[0].product_metadata.restaurantAddress1}{Object.values(cartDetails)[0].product_metadata.restaurantAddress2 ? `, ${Object.values(cartDetails)[0].product_metadata.restaurantAddress2}` : ''}, {Object.values(cartDetails)[0].product_metadata.restaurantCity} {Object.values(cartDetails)[0].product_metadata.restaurantState} {Object.values(cartDetails)[0].product_metadata.restaurantZipcode}</p>
                        </div>
                        </>
                    }
                </Col>
            </Row>
            {/* Shopping Cart and Summary */}
            <section className="bg-light my-0">
                <div className="container pt-4 pb-4">
                    {cartCount === 0 && 
                        <div className="alert alert-light text-center" role="alert"><p>Your shopping cart is empty.</p>
                            <p>Hungry? Checkout the amazing local <Link to={'/order'} data-umami-event={"Hungry link"}>restaurants serving
                                your neighborhood!</Link></p></div>
                    }
                    {cartCount > 0 &&
                        <Row>
                            <Col lg={8} sm={12}>
                                <ListGroup flush className="g-3">
                                    {Object.keys(cartDetails).map((itemId) => {
                                            const item = cartDetails[itemId];
                                            return (
                                                <ListGroup.Item key={item.id}
                                                                className={"d-flex justify-content-between"}>
                                                    <Row className={"flex-fill"}>
                                                        <Col md={12} lg={6} className="text-start mb-2">
                                                        <span className="fw-bold">{item.name}</span><br />
                                                            {item.description && <><text>{item.description}</text><br /><br /></>}
                                                            {item.product_metadata.specialInstructions && <p className="text-muted">{item.product_metadata.specialInstructions}</p>}
                                                            {item.product_metadata.modifiers && 
                                                            Object.keys(item.product_metadata.modifiers).map((key, x) => (
                                                                Array.isArray(item.product_metadata.modifiers[key]) && 
                                                                item.product_metadata.modifiers[key].map((modifierItem, y) => (
                                                                <div key={`${x}-${y}`} className="text-muted ms-2">
                                                                    {modifierItem.name}
                                                                </div>
                                                                ))
                                                            ))
                                                            }
                                                        </Col>
                                                        <Col md={12} lg={3} className="flex items-center">
                                                            <select
                                                                className="form-select"
                                                                value={item.quantity}
                                                                onChange={e => setItemQuantity(item.id, parseInt(e.target.value, 10))}
                                                                data-umami-event={"Quantity selected"}
                                                                >
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </select>
                                                        </Col>
                                                        <Col md= {12} lg={3} className="flex-fill flex-fill d-none d-lg-block">
                                                            <p>{item.formattedPrice}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                <Col md={12} lg={3} className="d-flex flex-column justify-content-center align-items-center ms-2">
                                                    <div className="d-block d-lg-none">{item.formattedPrice}</div>
                                                    <button
                                                    onClick={() => handleRemove(item)}
                                                    className="btn btn-link"
                                                    data-umami-event={"Remove from cart"}
                                                    >
                                                    <CartX size={25} color={"secondary"} />
                                                    </button>
                                                </Col>
                                                </Row>
                                                </ListGroup.Item>
                                            )
                                        }
                                    )}
                                </ListGroup>
                                <div className="d-flex justify-content-center m-3">
                                <Button variant="outline-secondary" onClick={() => navigate(`/menu/${Object.values(cartDetails)[0].product_metadata.restaurantId}`)}
                                    data-umami-event={"Add more items button"} 
                                    >
                                    Add more items
                                </Button>
                                </div>
                                </Col>
                            {/* Order Summary */}
                            <Col>
                                <Card className="p-3">
                                    {/* Display total price, discounts, tax, and final total */}
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2">Items in cart:</p>
                                        <p className="mb-2">{formattedTotalPrice}</p>
                                    </div>
                                    {/* Removing delivery fee
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2">Delivery fee:</p>
                                        <p className="mb-2">${(deliveryFee * 0.01).toFixed(2)}</p>
                                    </div> */}
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2">
                                            Tip
                                            <div className={'text-secondary small'}>100% of your tip is paid to your courier
                                            </div>
                                        </p>
                                        <p className="mb-2">
                                            <InputGroup controlId="tipField">
                                                <InputGroup.Text>$</InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="0.00"
                                                    defaultValue={tip}
                                                    onChange={event => updateTip(event)}
                                                    isInvalid={IsTipValid === false}
                                                    data-umami-event={"Tip field selected"}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a number with up to 2 decimal places.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </p>
                                    </div>
                                    {discountAmount > 0 &&
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 badge bg-success">
                                        <TagFill/> {couponDetails.description}</p>
                                        <p className="mb-2">{(-discountAmount * 0.01).toFixed(2)}</p>
                                    </div>
                                    }
                                    <div className="d-flex justify-content-between">
                                        <p className="fw-bold mb-2">Subtotal:</p>
                                        <p className="fw-bold 4">${orderSubtotalFormatted}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 me-2">
                                            Coupon
                                        </p>
                                        <p className="mb-2">
                                            <InputGroup controlId="couponCode">
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    defaultValue={couponDetails.couponcode}
                                                    isInvalid={couponDetails === 'error'}
                                                    onChange={event => setCouponCode(event.target.value)}
                                                    data-umami-event={"Coupon field selected"}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            getCouponDetails(couponCode);
                                                        }
                                                    }}                                                />
                                        <Button variant={'secondary'} onClick={() => getCouponDetails(couponCode)}>Apply</Button>
                                        </InputGroup>
                                        </p>
                                        </div>   
                                        {minNotMetError && <div className="justify-content-between text-center"><p><Form.Text className="text-danger">Order not elgible. Order  must be at least ${(couponDetails.ordermin * 0.01)}</Form.Text></p></div>}
                                        {couponDetails === 'error' && <div className="justify-content-between text-center"><p><Form.Text className="text-danger">Invalid coupon code</Form.Text></p></div>}
                                    {operatingStatus.status === 'closed' ?
                                        <Card>
                                            <ListGroup variant="flush">
                                            <ListGroup.Item className={'text-center'}>
                                                {operatingStatus.reason === 'override' ? (operatingStatus.message || "We are currently closed. Please try again later.") : null}
                                                {operatingStatus.reason === 'hours' && `We are currently closed. Our normal operating hours are ${operatingStatus.open.formatted} to ${operatingStatus.close.formatted} Monday - Sunday. Please come back during our operating hours to place an order.`}
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                        <Button className="btn btn-primary w-100 mb-2"
                                                onClick={handleCheckoutClick} variant="secondary" disabled> Checkout
                                        </Button>
                                            </ListGroup.Item>
                                            </ListGroup>
                                        </Card>
                                        :
                                    <Button className="btn btn-primary w-100 mb-2"
                                            onClick={handleCheckoutClick}
                                            data-umami-event={"Checkout button"}
                                            > Checkout
                                    </Button>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </section>
        </Container>
    )
};


export default ShoppingCartPage;
