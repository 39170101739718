import React, {useState, useRef, useEffect} from 'react';
import {Container, Row, Col, Form, Button, InputGroup, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/styles.css';
import '../css/home.css';
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import UprightNav from "../components/navbar"
import { AddressAutofill } from '@mapbox/search-js-react';
import pizzaIcon from '../assets/img/icons8-pizza-100.png';
import saladIcon from '../assets/img/icons8-salad-100.png';
import sushiIcon from '../assets/img/icons8-sushi-100.png';
import chineseNoodleIcon from '../assets/img/icons8-chinese-noodle-100.png';
import moreIcon from '../assets/img/icons8-more-100.png';
import pancakeIcon from '../assets/img/icons8-pancake-stack-100.png';
import hamburgerIcon from '../assets/img/icons8-hamburger-100.png';
import tacoIcon from '../assets/img/icons8-taco-100.png';
import cookieIcon from '../assets/img/icons8-cookie-100.png';
import {CartFill, GeoAltFill, TagFill} from "react-bootstrap-icons";
import TiktokPixel from "tiktok-pixel";
import restaurantResults from '../data/restaurant-results-1006.json';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import freeDeliveryIcon from '../assets/img/free-delivery-icon.png';
import noHiddenFeesIcon from '../assets/img/no-hidden-fees-icon.png';
import noSubscriptionIcon from '../assets/img/no-subscription-icon.png';


function LandingPage(effect, deps) {
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const navigate = useNavigate();
    const deliveryAddress = localStorage.getItem('deliveryAddress') ;
    const deliveryCoordinates = localStorage.getItem('deliveryCoordinates');
    const [manualInputValue, setManualInputValue] = useState('New York, NY 10016');
    const [selectedCuisine, setSelectedCuisine] = useState([]);
    const elementRefs = useRef([]);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        localStorage.setItem('deliveryAddress', 'New York, NY 10016');
        localStorage.setItem('deliveryCoordinates', JSON.stringify([-73.9797949, 40.7446009]));
    }, []);

    useEffect(() => {
        const trackedElements = new Set();
    
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && window.umami) {
                    const umamiName = entry.target.dataset.umamiName;
                    if (!trackedElements.has(umamiName)) {
                        trackedElements.add(umamiName);
                        window.umami.track(umamiName);
                    }
                }
            });
        });
    
        elementRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });
    
        return () => {
            elementRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);


    TiktokPixel.pageView(); // For tracking page view

    const handleCardClick = (restaurant, index) => {
        // Use the useNavigate hook to navigate to the desired page
        window.umami.track('Restaurant Card Click', {restaurantId: restaurant.restaurantId, index: index});
        navigate(`/menu/${restaurant.restaurantId}`);
    }; 

    const handleSearchClick = (e) => {
        e.preventDefault();
        localStorage.setItem('deliveryAddress', manualInputValue);
        navigate('/order');
    };

    const handleGeoButtonClick = () => {
            localStorage.removeItem('deliveryAddress');
            localStorage.removeItem('deliveryCoordinates')
            navigate('/order');
        };

    const handleManualAddressInput = (e) => {
        setManualInputValue(e);
    };

    const handleSelect = (data) => {
        const selectedAddress = data.features[0].properties.full_address
        // retrieve address from suggestionResults
        localStorage.setItem('deliveryAddress', selectedAddress)
        const selectedCoords = data.features[0].geometry.coordinates
        localStorage.setItem('deliveryCoordinates',JSON.stringify(selectedCoords))
        navigate('/order')
    };

    const scrollLeft = () => {
        scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };


    return (
        <Container>
            <Helmet>
                <title>Upright Bites</title>
                <meta name="description"
                      content="Delivery with integrity: Fair fees. Happy drivers. Thriving restaurants."/>
            </Helmet>
            <Row className={"justify-content-center p-3"}>
                <Col xs={8} md={3}>
                    <Form onSubmit={handleSearchClick}>
                        <AddressAutofill
                            accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                            onRetrieve={(data) => handleSelect(data)}
                        >
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        id="delivery-address"
                                        value={manualInputValue}
                                        onChange={(e) => (setManualInputValue(e.target.value))}
                                        data-umami-event={"delivery address search field"}
                                        
                                    />
                                    <InputGroup.Text
                                               onClick={handleGeoButtonClick} alt={"Use current location"} data-umami-event={"Geolocation button"}
                                               ><GeoAltFill
                                    size={20} /></InputGroup.Text>
                                </InputGroup>
                        </AddressAutofill>
                    </Form>
                    </Col>
                </Row>
            <div style={{ position: 'relative' }}>
                <Button
                    variant="light"
                    onClick={scrollLeft}
                    style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
                    className="d-none d-md-block"
                >
                    <ChevronLeft size={30} />
                </Button>
                <Container
                    ref={scrollContainerRef}
                    style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px 0', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                >
                    <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        {restaurantResults.map((restaurant, index) => (
                            <Col
                                md={3}
                                xs={9}
                                key={index}
                                className="mb-0"
                                style={{
                                    flex: '0 0 auto',
                                    padding: '0 10px',
                                    '@media (max-width: 576px)': {
                                        width: 'calc(100% - 100px)', // Show one card and a small portion of another
                                        padding: '0 5px',
                                    },
                                }}
                            >
                                <Card style={{ width: '100%', cursor: 'pointer' }} onClick={() => handleCardClick(restaurant, index)}
                                    data-index={index}
                                    ref={el => elementRefs.current[index] = el}
                                >
                                    <div className="position-relative">
                                        <Card.Img variant="top" className="img-fluid w-100" 
                                            style={{ height: '200px', objectFit: 'cover' }}  
                                        src={restaurant.cardimage} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title>{restaurant.name}
                                            <br />
                                            <div className="text-muted">{restaurant.cuisine}</div>
                                        </Card.Title>
                                        <Card.Text>
                                            {restaurant.address1} <br />
                                            {restaurant.address2 ? `${restaurant.address2} <br />` : ''} 
                                            {restaurant.city}, {restaurant.state} {restaurant.zipcode}<br />
                                            <div className='text-muted'>{restaurant.deliveryTime} min</div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
                <Button
                    variant="light"
                    onClick={scrollRight}
                    style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
                    className="d-none d-md-block"
                >
                    <ChevronRight size={30} />
                </Button>
            </div>
            <Row className="justify-content-center pb-3">
                <Col xs={12} md={6} className="text-center">
                <Button variant="outline-secondary" onClick={() => navigate('/order')} data-umami-event={"Browse all button"}>Browse all restaurants</Button>
                </Col>
            </Row>
           <div className={'pb-2 pt-0'}
                 style={{background: '#fbfbf1'}}>
                <Row className={'justify-content-center align-items-center'}>
                    <Col sm={1} md={'auto'} className={'text-center'}>
                        <h1><TagFill className={'text-primary'}/></h1>
                    </Col>
                    <Col sm={11} md={'auto'}>
                        <Row className={'text-center'}>
                            <h1 className={'mt-2 mb-0'}>40% off your first order</h1>
                        </Row>
                        <Row className={'text-center p-1'}>
                            <div>with coupon <span className={'p-1'} style={{ border: '1px dashed black', backgroundColor: 'lightgrey' }}> NEW40 </span></div>
                        </Row>
                        <Row className={'text-center'}>
                            <div className={'text-secondary'}><small>Maximum discount $10. No minimum order size required.<br/>
                                </small></div>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Container className="bg-light pt-3 pb-3">
                <Row>
                    <Col lg={4} ref={el => elementRefs.current[0] = el} data-umami-name={"$5 delivery fee value prop view"} > 
                    <Row className="d-flex flex-column align-items-center text-center">
                    <img src={freeDeliveryIcon} style={{ height: "100px", width: "auto" }} alt="Free Delivery on Every Order" />
                                                <h3 className={'mt-2'}>Free delivery on every order</h3>
                        </Row>
                    </Col>
                    <Col lg={4} ref={el => elementRefs.current[1] = el} data-umami-name={"Safe and reliable deliveries value prop view"}>
                    <Row className="d-flex flex-column align-items-center text-center">
                        <img src={noHiddenFeesIcon} style={{ height: "100px", width: "auto" }} alt="No hidden fees" />
                            <h3 className={'mt-2'}>No hidden fees</h3>
                        </Row>
                    </Col>
                    <Col lg={4} ref={el => elementRefs.current[2] = el} data-umami-name={"Support local restaurants value prop view"}>
                    <Row className="d-flex flex-column align-items-center text-center">
                        <img src={noSubscriptionIcon} style={{ height: "100px", width: "auto" }} alt="No Memberships or Subscription Required" />
                            <h3 className={'mt-2'}>No subscription plans</h3>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="FAQs d-grid gap-5">
            <Row ref={el => elementRefs.current[3] = el} data-umami-name={"FAQ section view"}>
                    <Col>
                        <h3 className="text-center">Frequently Asked Questions</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} ref={el => elementRefs.current[4] = el} data-umami-name={"FAQ1 view"}>
                        <Row><p><b>What is Upright Bites?</b></p></Row>
                        <Row><p>Upright Bites is a food delivery platform committed to free delivery and no hidden fees.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[5] = el} data-umami-name={"FAQ2 view"}>
                        <Row><p><b>What sets Upright Bites apart from other food delivery services?</b></p></Row>
                        <Row><p>Our focus on free delivery and transparency sets us apart from competing services
                            like Uber Eats, Doordash, Grubhub or Seamless.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[6] = el} data-umami-name={"FAQ3 view"}>
                        <Row><p><b>Where does Upright Bites source its food?</b></p></Row>
                        <Row><p>We partner with local restaurants to bring you a diverse and delicious menu, supporting
                            the culinary scene in your area.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[7] = el} data-umami-name={"FAQ3=4 view"}>
                        <Row><p><b>How can Upright Bites afford to offer free delivery?</b></p></Row>
                        <Row><p>Upright Bites uses proprietary technology to streamline operations and complete deliveries more efficiently than our competitors. This allows us to provide free delivery without compromising on service or quality.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[8] = el} data-umami-name={"FAQ5 view"}>
                        <Row><p><b>What areas does Upright Bites currently serve?</b></p></Row>
                        <Row><p>Upright Bites currently operates within select neighborhoods in New York City. Please enter your address on our homepage to see if we deliver to your area. If we don't serve your area yet, please check back soon as we are rapidly expanding!</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[9] = el} data-umami-name={"FAQ6 view"}>
                        <Row><p><b>What if I have an issue with my order?</b></p></Row>
                        <Row><p>We are committed to delivering a best in class experience to our customers. If anything should go wrong, please email us at <a className={'text-decoration-none text-body'} href={'mailto:help@uprightbites.com'}>help@uprightbites.com</a> or call/text us at <a className={'text-decoration-none text-body'} href='tel:646-535-7020'>646-535-7020</a> so that we can make it right.</p></Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default LandingPage;